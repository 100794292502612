@use '../base/variables' as v;

.cesta {

    .contenedorProductos {
        h2 {
            margin-bottom: 32px;
        }

        p {
            font-size: 20px;
            margin-bottom: 32px;

            span {
                font-weight: v.$bold;
            }
        }

        a {
            margin-bottom: 32px;
        }

        .productos {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 18px;

            .producto {
                max-width: 440px;
                width: 100%;
                height: 187px;
                background-color: v.$gris;
                border-radius: 20px;
                padding: 7px;
                margin: 0;

                .contenidoProducto {
                    display: flex;
                    gap: 18px;

                    img {
                        width: 195px;
                        height: 173px;
                        margin: 0;
                        border-radius: 20px;
                    }

                    .data {
                        max-width: 180px;
                        width: 100%;
                        max-height: 152px;
                        margin: auto 0;

                        h4,
                        p,
                        span,
                        .contador {
                            min-width: 100%;
                        }

                        h4 {
                            font-size: 20px;
                            font-weight: v.$bold;
                            // margin-bottom: 2px;
                        }

                        p {
                            font-size: 12px;
                            color: rgba(v.$negro, .6);
                            margin: 0;
                            // margin-bottom: 18px;
                        }

                        span {
                            font-size: 18px;
                            color: rgba(v.$negro, .6);
                            display: flex;
                            // margin-bottom: 18px;
                            margin: 18px 0;
                        }

                        .contador {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 20px;
                            border: 1px solid rgba(v.$negro, .2);
                            padding: 8px;
                            height: 40px;

                            .cantidad {

                                span {
                                    margin: 0;
                                    font-size: 20px;
                                }
                            }

                            .botonMenos,
                            .botonMas {

                                img {
                                    width: 22px;
                                    height: 22px;
                                    aspect-ratio: 1/1;
                                    object-fit: cover;
                                }

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cesta-vacia {
        margin: 0 auto;
        max-width: 264px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 17px;

        img {
            height: 60px;
            width: 60px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }

        p {
            font-size: 24px;
            text-align: center;
        }
    }
}