@use '../base/variables' as v;

.contacto {

    h2 {
        margin-bottom: 28px;
    }

    .formularioContacto {
        display: flex;
        flex-direction: column;
        gap: 28px;
        justify-content: center;
        align-items: center;
    }
}