@use '../base/variables' as v;

.adminProductos {

    .barraAcciones {
        margin-bottom: 34px;
    }

    h2 {
        margin-bottom: 28px;
    }

    a {

        &.agregar {
            margin-bottom: 28px;
        }
    }
}

.crearProducto {

    h2 {
        margin-bottom: 36px;
    }

    form {
        display: flex;
        flex-direction: column;

        button {

            &.actualizarProducto {
                margin-top: 36px;
            }
        }

        input[name="producto[nombre]"] {
            margin-bottom: 36px;
            font-size: 24px;
            border: none;
            font-weight: v.$bold;

            &:focus {
                outline: none;
            }
        }


        .inputsImage {
            display: grid;
            grid-template-areas:
                "inputImage1 inputImage2"
                "inputImage3 inputImage4";
            gap: 10px;
            margin-bottom: 36px;

            .inputImage {
                width: 215px;
                height: 130px;
                display: flex;
                justify-content: center;
                align-items: center;

                border: 2px dashed lighten(v.$negro, 70);
                border-radius: 20px;

                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                &:first-child {
                    grid-area: inputImage1;
                }

                &:nth-child(2) {
                    grid-area: inputImage2;
                }

                &:nth-child(3) {
                    grid-area: inputImage3;
                }

                &:last-child {
                    grid-area: inputImage4;
                }

                img {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                    aspect-ratio: 1/1;
                }

                &:hover {
                    cursor: pointer;
                }

                input {
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}