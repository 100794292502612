@use '../base/variables' as v;


.articulos {
    
    h2 {
        margin-bottom: 28px;
        height: 29px;
    }

    .verTodos {
        max-width: 440px;
        width: 100%;
        text-align: center;

        a {
            color: v.$verdeClaro;
            text-decoration: none;
            font-size: 14px;
            font-style: italic;
            font-weight: v.$bold;
        }
    }

    .contenidoArticulos {
        display: grid;
        grid-template-areas:
            'a b'
            'a c'
            'd c ';
        gap: 8px;
        margin-bottom: 28px;
        height: 357px;

        .articulo {
            width: 214.03px;
            border-radius: 20px;
            padding: 24px 12px;

            a {
                text-decoration: none;

                h4 {
                    margin: 0;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    width: 190px;
                    color: v.$blanco;
                    font-size: 16px;
                    font-weight: v.$bold;
                }
            }

            &:first-child,
            &:nth-child(3) {
                height: 230px;
            }

            &:nth-child(2),
            &:nth-child(4) {
                height: 115px;
            }

            &:first-child {
                grid-area: a;
            }

            &:nth-child(2) {
                grid-area: b;
            }

            &:nth-child(3) {
                grid-area: c;
            }

            &:nth-child(4) {
                grid-area: d;
            }
        }
    }
}

.articulo {

    .detallesArticulo {

        .descripcion {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            // gap: 12px;
            max-width: 440px;
            width: 100%;

            p {
                margin: 0;
                max-width: 440px;
                width: 100%;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                max-width: 440px;
                width: 100%;
                margin-top: 20px;
            }
        }

        .fechaBy {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 1px;
            max-width: 440px;
            width: 100%;
            margin-bottom: 20px;

            p {
                margin: 0;
                color: lighten(v.$negro, 50);
                font-size: 12px;
                max-width: 440px;
                width: 100%;
            }
        }

        .imagenTitulo {

            img {
                margin: 0;
                max-width: 440px;
                width: 100%;
                // height: 238px;
                height: 440px;
                border-radius: 20px;
                margin-bottom: 24px;
                object-fit: cover;
            }

            h2 {
                font-size: 24px;
                font-weight: v.$bold;
                margin-bottom: 20px;
            }
        }
    }

    .atras {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        a {
            max-width: 480px;
            width: 100%;

            img {
                margin: 0;
                width: 30px;
                height: 30px;
                object-fit: cover;
            }
        }
    }
}