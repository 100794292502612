@use '../base/variables' as v;

header {
    // border-bottom: 1px solid v.$negro;
    height: 60px;
    width: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
        height: 49px;
        width: 119px;
    }
}