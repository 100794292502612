@use 'variables' as v;

html {
    font-size: 62.5%;
    box-sizing: border-box;
    scroll-padding-top: 0rem;
    height: 100vh;
    // background-color: v.$negro;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    // margin: 0;
    font-family: v.$mainFont;
    // height: 100%;

    display: grid;
    min-height: 100vh;
    grid-template-rows: auto 1fr auto;

    max-width: 480px;
    margin: 0 auto;
    background-color: v.$blanco;
}

main {
    max-width: 800px;
    margin: 16px 0;
    padding: 36px 20px;
    background-color: v.$blanco;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

img {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: v.$bold;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

.contenedor {
    // margin: 16px auto;
    max-width: 480px;
    width: 100%;

    margin: auto;
}

p {
    font-size: 16px;
    margin: 0;
}

a {
    text-decoration: none;
}

blockquote {
    max-width: 440px;
    width: 100%;
    border-left: 4px solid v.$verdeClaro;
    color: v.$negro;
    font-style: italic;
    background-color: rgba(0, 0, 0, .05);
    padding: 10px 16px;
    margin: 5px 0;
}

.botonVerdeClaro {
    color: v.$verdeClaro;
    border: 1px solid v.$verdeClaro;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: v.$bold;
    background-color: transparent;
    height: 50px;
    max-width: 440px;
    width: 100%;
    font-size: 20px;
    padding: 0;

    p {
        font-size: 20px;
    }

    &:hover {
        color: v.$blanco;
        cursor: pointer;
    }

    &::after {
        content: "";
        background: v.$verdeClaro;
        position: absolute;
        z-index: -1;
        display: block;
        left: -50%;
        right: -50%;
        top: -50%;
        bottom: -50%;
        transform: skewX(-45deg) scale(0, 1);
        transition: all 0.5s ease;
    }

    &:hover::after {
        transition: all 0.5s ease-out;
        transform: skewX(-45deg) scale(1, 1);
    }
}

.botonRojoClaro {
    color: v.$rojoClaro;
    border: 1px solid v.$rojoClaro;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: v.$bold;
    background-color: transparent;
    height: 50px;
    max-width: 440px;
    width: 100%;
    font-size: 20px;
    padding: 0;

    p {
        font-size: 20px;
    }

    &:hover {
        color: v.$rojo;
        cursor: pointer;
    }

    &::after {
        content: "";
        background: v.$rojoClaro;
        position: absolute;
        z-index: -1;
        display: block;
        left: -50%;
        right: -50%;
        top: -50%;
        bottom: -50%;
        transform: skewX(-45deg) scale(0, 1);
        transition: all 0.5s ease;
    }

    &:hover::after {
        transition: all 0.5s ease-out;
        transform: skewX(-45deg) scale(1, 1);
    }
}

.formulario {
    max-width: 440px;
    width: 100%;

    input,
    textarea {
        margin: 0;
        border-radius: 20px;
        width: 100%;
        border: none;
        background-color: v.$gris;
        font-size: 18px;
        font-weight: v.$regular;

        &:focus {
            outline: none;
        }
    }

    input {
        padding: 14px 20px;
        height: 50px;
    }

    textarea {
        resize: none;
        height: 240px;
        padding: 20px;
    }
}

.paginador {
    max-width: 274px;
    width: 100%;
    margin: 0 auto;
    height: 40px;
    margin-top: 34px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    position: relative;
    /* Necesario para el efecto de desvanecimiento */

    a {
        img {
            height: 24px;
            width: 24px;
        }
    }

    .numeros {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        max-width: 206px;
        width: 100%;
        overflow: hidden;
        position: relative;

        a {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: lighten(v.$negro, 50);
            font-size: 20px;

            &.selected {
                background-color: v.$verdeClaro;
                color: v.$blanco;
            }
        }

        /* Efecto de desvanecimiento */
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 1px;
            pointer-events: none;
        }

        &::before {
            left: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        }

        &::after {
            right: 0;
            background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        }
    }
}

// Alert animation
@keyframes alertAnimation {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    20% {
        transform: translateX(0);
        opacity: 1;
    }

    40% {
        transform: translateX(10%);
    }

    60% {
        transform: translateX(0);
    }

    80% {
        transform: translateX(5%);
    }

    100% {
        transform: translateX(0);
    }
}

// Alert exit animation
@keyframes alertExitAnimation {
    0% {
        transform: scale(1) translateX(0) skewX(0deg);
        opacity: 1;
    }

    100% {
        transform: scale(0.8) translateX(100%) skewX(-10deg);
        opacity: 0;
    }
}

.navegacion {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 46px;

    a {
        color: rgba(v.$negro, .5);

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            aspect-ratio: 1/1;
        }
    }
}