@use '../base/variables' as v;

.profile {
    display: flex;
    flex-direction: column;
    gap: 67px;
    max-width: 420px;
    width: 100%;

    .options {
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .option {
            background-color: v.$gris;
            display: flex;
            justify-content: space-between;
            padding: 15px 23px;
            border-radius: 20px;
            width: 400px;

            &.cerrarSesion {
                background-color: v.$rojoClaro;

                p {
                    color: v.$rojo;
                    width: 100%;
                    text-align: center;
                }
            }

            p {
                font-size: 16px;
                font-weight: v.$bold;
                color: v.$negro;
            }

            img {
                margin: 0;
                height: 20px;
                width: 20px;
                object-fit: cover;
                aspect-ratio: 1/1;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .personalData {
        max-width: 160px;
        max-height: 236px;
        height: 100%;

        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .imageProfile {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            background-color: v.$gris;


            img {
                width: 160px;
                height: 160px;
                aspect-ratio: 1/1;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .completeNameAndEmail {
            max-width: 150px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .email {
                font-size: 14px;
                color: rgba(v.$negro, .5);
            }

            .nombre {
                max-width: 125px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                columns: 2px;

                p {
                    font-size: 16px;
                    font-weight: v.$regular;
                    text-align: center;
                }
            }
        }
    }
}