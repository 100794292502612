@use '../base/variables' as v;

.barraAcciones {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .iconos {
        display: flex;
        align-items: center;
        width: 104.27px;
        justify-content: space-between;

        .carrito {
            position: relative;

            a {
                img {
                    width: 43px;
                    height: 40px;
                }
            }

            p {
                position: absolute;
                top: -4px;
                right: -1px;
                background-color: v.$rojo;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: v.$blanco;
                font-weight: v.$bold;
                font-size: 10px;
            }
        }

        .perfil {
            a {
                img {
                    width: 43px;
                    height: 40px;
                    aspect-ratio: 1/1;
                }
            }
        }
    }

    .buscador {
        position: relative;
        max-width: 292px;
        width: 100%;

        input {
            height: 40px;
            width: 292px;
            padding: 0;
            margin: 0;
            border: none;
            border-radius: 10px;
            background-color: v.$gris;
            padding: 8px;
            padding-left: 32px;

            font-size: 12px;
            font-weight: v.$regular;

            &:focus {
                outline: none;
            }
        }

        img {
            width: 22px;
            height: 22px;
            position: absolute;
            top: 10px;
            left: 8px;
        }
    }
}

.contenidoProductos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    height: 382px;

    .producto {
        width: 141.33px;
        height: 187px;
        border-radius: 20px;
        background-color: v.$gris;
        padding: 6px 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 0;

        .contenido {
            text-decoration: none;

            img {
                margin: 0;
                width: 124.7px;
                height: 110px;
                border-radius: 15px;
                object-fit: cover;
                // background-color: #DAE0DA;
                background-color: #E7E8D8;
            }

            .nombrePrecio {

                h4 {
                    font-size: 14px;
                    font-weight: v.$bold;
                    margin: 9px 0;
                    text-align: center;
                    color: v.$negro;
                    height: 17px;
                    overflow: hidden;
                }

                p {
                    font-size: 14px;
                    color: lighten(v.$negro, 60);
                    text-align: center;
                    margin: 0;
                    height: 17px;
                    font-weight: v.$bold;
                }
            }
        }
    }
}

.index {
    margin: 16px auto;
    max-width: 480px;
    width: 100%;

    .productos {
        margin-bottom: 46px;

        h2 {
            font-size: 24px;
            font-weight: v.$bold;
            margin: 0;
            margin-bottom: 18px;
            height: 29px;
        }

        .contenidoProductos {
            margin-bottom: 18px;
        }

        .verTodos {
            max-width: 440px;
            width: 100%;
            text-align: center;

            a {
                color: v.$verdeClaro;
                text-decoration: none;
                font-size: 14px;
                font-style: italic;
                font-weight: v.$bold;
            }
        }
    }

    .banner {
        height: 200px;
        max-width: 440px;
        width: 100%;
        margin-bottom: 46px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    .barraAcciones {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 46px;

        .iconos {
            display: flex;
            align-items: center;
            width: 104.27px;
            justify-content: space-between;

            .carrito {
                a {
                    img {
                        width: 43px;
                        height: 40px;
                    }
                }
            }

            .perfil {
                a {
                    img {
                        width: 43px;
                        height: 40px;
                    }
                }
            }
        }

        .buscador {
            position: relative;
            max-width: 292px;
            width: 100%;

            input {
                height: 40px;
                width: 292px;
                padding: 0;
                margin: 0;
                border: none;
                border-radius: 10px;
                background-color: v.$gris;
                padding: 8px;
                padding-left: 32px;

                font-size: 12px;
                font-weight: v.$regular;

                &:focus {
                    outline: none;
                }
            }

            img {
                width: 22px;
                height: 22px;
                position: absolute;
                top: 10px;
                left: 8px;
            }
        }
    }
}