@use '../base/variables' as v;

.misDatos {

    h2 {
        margin-bottom: 36px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 22px;

        button[type="submit"] {
            margin-top: 16px;
        }

        .imagen {
            width: 160px;
            height: 160px;
            position: relative;
            margin: 0 auto;
            margin-bottom: 40px;
            border-radius: 50%;

            img {
                width: 160px;
                height: 160px;
                margin: 0;
                background-color: v.$gris;
                border-radius: 50%;
            }

            input {
                position: absolute;
                width: 160px;
                height: 160px;
                opacity: 0;
                cursor: pointer;
            }
        }

        .input {
            position: relative;

            label {
                color: rgba(v.$negro, .5);
                background-color: v.$blanco;
                position: absolute;
                left: 30px;
                top: -15px;
                font-size: 16px;
                padding: 5px;
            }

            input {
                height: 60px;
                width: 440px;
                border-radius: 20px;
                border: 1px solid rgba(v.$negro, .2);
                padding: 0;
                margin: 0;

                padding: 14px 35px;
                font-size: 18px;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}