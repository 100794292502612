@use '../base/variables' as v;

.adminUsuarios {

    h2 {
        margin-bottom: 24px;
    }

    .noData {
        max-width: 440px;
        width: 100%;
        margin: 40px auto;
        text-align: center;
        font-size: 16px;
        color: rgba(v.$negro, .5);
        font-weight: v.$bold;
    }

    .usuarios {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);

        .usuario {
            background-color: v.$gris;
            border-radius: 20px;
            height: 80px;
            max-width: 215px;
            width: 100%;
            padding: 10px;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;

            img {
                width: 60px;
                height: 60px;
                aspect-ratio: 1/1;
                object-fit: cover;
                border-radius: 50%;
                margin: 0;
            }

            .data {
                max-width: 104px;
                width: 100%;
                overflow: hidden;
                height: auto;

                h4 {
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                    color: v.$negro;
                }

                p {
                    font-size: 10px;
                    color: rgba(v.$negro, .5);
                    height: auto;
                }
            }
        }
    }
}

.adminActualizarUsuario {

    .imagenNombre {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
        margin-bottom: 36px;

        img {
            width: 140px;
            height: 140px;
            aspect-ratio: 1/1;
            object-fit: cover;
            margin: 0;
            border-radius: 50%;
        }

        .nombre {
            max-width: 235px;
            width: 100%;
        }
    }

    .datos {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;

        select,
        input,
        textarea {
            max-width: 440px;
            width: 100%;
            background-color: v.$gris;
            border-radius: 20px;
            height: 50px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 14px 20px;

            text-align: center;
            font-size: 18px;
            color: rgba(v.$negro, .5);
            resize: none;

            &.bloqueado {
                background-color: v.$rojoClaro;
                color: v.$rojo;
                font-weight: v.$bold;
                resize: none;
                height: 71px;
            }

            &.noConfirmado {
                background-color: v.$amarilloClaro;
                color: v.$amarillo;
                font-weight: v.$bold;
            }

            &.confirmado {
                background-color: rgba(v.$verdeClaro, .6);
                color: v.$verde;
                font-weight: v.$bold;
            }
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

    }
}