@use '../base/variables' as v;

.btnFiltro {
    max-width: 440px;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    border: none;
    background-color: transparent;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;

    &:hover {
        cursor: pointer;
    }

    img {
        height: 40px;
        width: 40px;
        margin: 0;
        object-fit: cover;
        aspect-ratio: 1/1;
    }
}

.filtros {
    margin-bottom: 24px;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity .5s ease, max-height .5s ease;

    a {
        font-size: 14px;
        color: v.$verdeClaro;
        font-weight: v.$bold;
        font-style: italic;
        text-decoration: underline;
    }

    &.mostrar {
        display: flex;
        opacity: 1;
        max-height: 500px;
    }

    .nombre {
        display: flex;
        gap: 10px;
    }
}

.misPedidos {

    .btnFiltro {
        max-width: 440px;
        width: 100%;
        justify-content: flex-end;
        display: flex;
        border: none;
        background-color: transparent;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-bottom: 24px;

        &:hover {
            cursor: pointer;
        }

        img {
            height: 40px;
            width: 40px;
            margin: 0;
            object-fit: cover;
            aspect-ratio: 1/1;
        }
    }

    .filtros {
        margin-bottom: 24px;
        display: none;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity .5s ease, max-height .5s ease;

        a {
            font-size: 14px;
            color: v.$verdeClaro;
            font-weight: v.$bold;
            font-style: italic;
            text-decoration: underline;
        }

        &.mostrar {
            display: flex;
            opacity: 1;
            max-height: 500px;
        }
    }

    .tituloPedidoAdmin {
        margin-bottom: 24px;
    }

    h2 {
        margin-bottom: 57px;
    }

    .pedidos {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .sinResultado {
            font-size: 16px;
            margin: 40px 0;
            color: rgba(v.$negro, .5);
            font-weight: v.$bold;
            text-align: center;
        }

        .pedido {
            padding: 14px 18px;
            border-radius: 20px;
            border: 1px dashed rgba(v.$negro, .2);

            max-height: 210px;
            max-width: 440px;
            width: 100%;

            .masproductos {
                margin-top: 13px;
                width: 100%;
                border-radius: 20px;
                background-color: v.$gris;
                height: 30px;

                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: v.$bold;
                    color: rgba(v.$negro, $alpha: .3);
                }
            }

            .detallesProductoTotalPedido {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .total {
                    font-size: 16px;
                    margin: 0;
                }

                .producto {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0;
                    max-width: 186px;
                    width: 100%;
                    height: 80px;


                    img {
                        width: 80px;
                        height: 80px;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        border-radius: 20px;
                        margin: 0;
                    }

                    .data {
                        margin-top: -20px;

                        h4 {
                            font-size: 16px;
                        }

                        p {
                            font-size: 10px;
                            color: rgba(v.$negro, .5);
                        }
                    }
                }
            }

            hr {
                margin: 13px 0;
                color: rgba(v.$negro, .1);
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-content: center;

                .numPedidoFecha {

                    .numPedido {
                        color: v.$verdeClaro;
                        margin: 0;
                        margin-bottom: 2px;
                        font-size: 16px;
                        font-weight: v.$bold;
                    }

                    .fecha {
                        font-size: 10px;
                        margin: 0;
                        color: rgba(v.$negro, .5);
                        font-weight: v.$regular;
                    }
                }

                .acciones {
                    display: flex;
                    align-items: center;
                    gap: 18px;

                    a {
                        display: flex;
                        gap: 2px;

                        img {
                            width: 12px;
                            height: 12px;
                            aspect-ratio: 1/1;
                            object-fit: cover;
                            margin: 0;
                        }

                        p {
                            font-size: 10px;
                            margin: 0;
                            color: v.$azulClaro;
                        }
                    }
                }
            }
        }
    }
}

.misPedidosDetalles {

    .estadoPedido {
        border: 1px dashed rgba(v.$negro, .2);
        padding: 14px 18px;
        border-radius: 20px;
        margin-bottom: 10px;

        form {
            display: flex;
            flex-direction: column;
            gap: 8px;

            select {
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                height: 50px;
                border-radius: 16px;
                padding: 14px 20px;
                font-size: 14px;

                -webkit-appearance: none;
                /* Safari y Chrome */
                -moz-appearance: none;
                /* Firefox */
                appearance: none;
                /* Estándar */
            }
        }

        .mensaje {
            border-radius: 16px;
            padding: 14px 18px;

            &.exito {
                background-color: v.$verdeClaro;

                p {
                    color: v.$blanco;
                }
            }

            &.error {
                background-color: v.$rojoClaro;

                p {
                    color: v.$rojo;
                }
            }

            p {
                font-size: 16px;
                margin: 0;
                text-align: center;
                font-weight: v.$bold;
            }
        }
    }

    .pedido {
        max-width: 440px;
        width: 100%;
        padding: 14px 18px;
        border-radius: 20px;
        border: 1px dashed rgba(v.$negro, .2);

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .numPedidoFecha {

                h3 {
                    color: v.$verdeClaro;
                    margin: 0;
                    margin-bottom: 2px;
                    font-size: 20px;
                    font-weight: v.$bold;
                }

                p {
                    font-size: 12px;
                    margin: 0;
                    color: rgba(v.$negro, .5);
                    font-weight: v.$regular;
                }
            }

            .acciones {
                display: flex;
                align-items: center;
                gap: 18px;

                .recibo {
                    display: flex;
                    gap: 2px;

                    img {
                        width: 16px;
                        height: 16px;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        margin: 0;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    button {
                        font-size: 14px;
                        margin: 0;
                        color: v.$azulClaro;
                        border: none;
                        background-color: transparent;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        hr {
            margin: 13px 0;
            color: rgba(v.$negro, .1);
        }

        .productos {
            display: flex;
            flex-direction: column;
            gap: 18px;

            .producto {
                background-color: v.$gris;
                padding: 7px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                margin: 0;
                gap: 18px;

                img {
                    border-radius: 16px;
                    margin: 0;
                }

                .data {
                    margin-top: -8px;

                    .precio,
                    .cantidad {
                        color: rgba(v.$negro, .6);
                    }

                    .precio {
                        margin: 4px 0;
                    }
                }
            }
        }

        .resumenTotal {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 30px;

            .totalProductos,
            .precioEnvio,
            .total {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    margin: 0;
                    font-size: 20px;
                }
            }

            .total {

                p {
                    font-weight: v.$bold;
                }
            }
        }
    }

    .envio {
        max-width: 440px;
        width: 100%;
        padding: 14px 18px;
        border-radius: 20px;
        border: 1px dashed rgba(v.$negro, .2);
        margin-bottom: 10px;

        display: flex;
        flex-direction: column;
        gap: 18px;
    }
}