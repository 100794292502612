@use '../base/variables' as v;


footer {
    height: 60px;
    width: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .contenido {
        color: lighten(v.$negro, 50);
        width: 440px;
        height: 24px;
        font-size: 10px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .derechosAutor {

            p {
                margin: 0;
                width: 125px;
                font-size: 10px;
            }
        }

        .politicas {
            display: flex;
            align-items: flex-end;
            gap: 18px;

            a {
                text-decoration: none;
                color: lighten(v.$negro, 50);
                font-size: 10px;

                &:first-child {
                    width: 39px;
                    height: 12px;
                }

                &:nth-child(2) {
                    width: 45px;
                    height: 24px;
                }

                &:last-child {
                    width: 52px;
                    height: 24px;
                }
            }
        }
    }
}