@use '../base/variables' as v;

.opcionesEnvio {

    .alertas {
        .alerta {
            p {
                margin: 0;
            }
        }
    }

    h2 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 42px;
    }

    .aceptaLegal {

        p {
            margin: 0;
            font-size: 12px;
            text-align: center;
            margin-top: 20px;

            a {
                color: v.$verde;
                font-weight: v.$bold;
            }
        }
    }

    .resumenPedido {
        margin: 50px 0;

        display: flex;
        flex-direction: column;
        gap: 15px;

        .totalProductos,
        .precioEnvio,
        .total {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                margin: 0;
                font-size: 20px;
            }
        }

        .total {

            p {
                font-weight: v.$bold;
            }
        }
    }

    .opciones {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .opcion {
            background-color: v.$gris;
            border-radius: 20px;

            display: flex;
            gap: 20px;
            align-items: center;
            max-width: 440px;
            width: 100%;
            height: 80px;
            padding: 18px 14px;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                background-color: v.$verdeClaro;

                .data {

                    p {
                        color: v.$blanco;
                    }
                }
            }

            img {
                margin: 0;
                width: 30px;
                height: 30px;
                aspect-ratio: 1/1;
                object-fit: cover;
            }

            .data {
                display: flex;
                flex-direction: column;
                gap: 8px;

                p {
                    margin: 0;
                    font-size: 16px;
                    font-weight: v.$bold;

                    &:nth-child(2) {
                        font-size: 14px;
                        font-weight: v.$regular;
                    }
                }
            }
        }
    }
}