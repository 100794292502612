@use '../base/variables' as v;

.adminEnvios {

    h2 {
        margin-bottom: 24px;
    }

    .envios {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        .envio {
            border-radius: 20px;
            background-color: v.$gris;
            padding: 20px;

            h3 {
                color: v.$negro;
            }

            p {
                color: rgba(v.$negro, .6);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.crearEnvio {

    h2 {
        margin-bottom: 28px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 14px;

        button {
            margin-top: 18px;
        }
    }
}