@use '../base/variables' as v;

.singUp {

    .yaTienesCuenta {
        margin: 0 auto;
        // max-width: 180px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 18px;

            a {
                font-weight: v.$bold;
                color: v.$verdeClaro;
            }
        }
    }

    .aceptarLegal {
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 20px;

        p {
            font-size: 12px;
            text-align: center;

            a {
                color: v.$verde;
                font-weight: v.$bold;
            }
        }
    }

    h2 {
        margin-bottom: 22px;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 22px;

        margin-bottom: 22px;

        .olvide {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: -14px;

            a {
                color: v.$verdeClaro;
                font-weight: v.$bold;
                font-size: 14px;
            }
        }

        .password {
            position: relative;
            max-width: 440px;
            width: 100%;

            input {
                max-width: 440px;
                width: 100%;
            }

            img {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                height: 30px;
                width: 30px;
                
                &:hover {
                    cursor: pointer;
                }
            }
        }

        input {
            height: 50px;
            border-radius: 20px;
            max-width: 440px;
            width: 100%;
            border: none;
            background-color: v.$gris;
            padding: 14px 20px;

            font-size: 18px;
            font-weight: v.$regular;

            &:focus {
                outline: none;
            }
        }
    }
}