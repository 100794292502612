@use '../base/variables' as v;

.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: v.$bold;
    color: rgba(v.$negro, .5);
    width: 440px;
    height: 357px;
}

// Styles of alert message
.alertas {
    position: fixed;
    top: 90px;
    right: 15px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .alerta {

        &.error {
            background-color: #feeded;

            p {
                color: #f6a9a9;
            }
        }

        &.exito {
            background-color: #c6e8d4;

            p {
                color: #6fc391;
            }
        }

        min-height: 60px;
        border-radius: 20px;
        padding: 12px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        max-width: 285px;
        width: 100%;

        animation: v.$alertAnimation;

        p {
            font-size: 14px;
            font-weight: v.$bold;
            max-width: 194px;
            width: 100%;
        }

        img {
            width: 35px;
            height: 35px;
            object-fit: cover;
            aspect-ratio: 1/1;
            margin: 0;
        }
    }
}

// Styles of button to add new article, product, etc.
.agregar {
    max-width: 440px;
    width: 100%;
    background-color: v.$gris;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-bottom: 24px;

    img {
        margin: 0;
        width: 48px;
        height: 48px;
        object-fit: cover;
        aspect-ratio: 1/1;
    }
}

.admin {

    h2 {
        margin-bottom: 30px;
    }

    .opciones {
        max-width: 440px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .opcion {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: v.$gris;
            max-width: 440px;
            width: 100%;
            height: 50px;
            border-radius: 20px;

            &:hover {
                cursor: pointer;
            }

            .contenido {
                max-width: 380px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: v.$negro;

                a {
                    color: v.$negro;
                    font-size: 16px;
                    font-weight: v.$bold;
                }

                img {
                    width: 24px;
                    height: 24px;
                    object-fit: cover;
                    aspect-ratio: 1/1;
                    margin: 0;
                    rotate: -90deg;
                }
            }
        }
    }
}