@use '../base/variables' as v;

.datosProducto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 55px;

    .texto {
        details {
            max-width: 440px;

            summary {
                font-size: 20px;
                margin-bottom: 22px;
            }

            .ck-editor {
                margin-top: 22px;
            }

            input,
            textarea {
                border: none;
                resize: none;
                max-width: 440px;
                padding: 0;
                margin: 0;
                margin-top: 22px;
                font-size: 16px;
                text-align: left;
                width: 440px;
                min-height: 60px;
                height: auto;

                &:focus {
                    outline: none;
                }
            }

            input[type="number"] {
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }


        }

        details summary {
            list-style: none;
            cursor: pointer;
            position: relative;
            // padding-right: 20px;
        }

        /* Ocultar la flecha predeterminada */
        summary::-webkit-details-marker {
            display: none;
        }

        summary::marker {
            display: none;
        }

        /* Agregar y posicionar el SVG personalizado */
        summary::after {
            content: url('/build/img/mostrarDetalles.svg');
            position: absolute;
            right: 0;
            top: 64%;
            transform: translateY(-50%);
            transition: transform 0.3s ease;
        }

        /* Rotar el SVG cuando el details está abierto */
        details[open] summary::after {
            transform: translateY(-64%) rotate(180deg);
        }
    }
}

.producto {
    margin: 16px auto;
    max-width: 480px;
    width: 100%;

    .detallesProducto {
        .botonAgregarCesta {
            margin-bottom: 22px;
        }

        .cantidadProducto {
            position: relative;
            max-width: 440px;
            width: 100%;
            margin-bottom: 12px;

            .textoCantidad {
                width: 80px;
                height: 20px;
                margin: 0 5px;
                background-color: v.$blanco;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 16px;
                font-weight: v.$bold;
                color: lighten(v.$negro, 50);
                position: absolute;
                left: 180px;
                top: -10px;
            }

            .contador {
                display: flex;
                align-items: center;
                border-radius: 20px;
                border: 1px solid lighten(v.$negro, 80);
                padding: 10px 25px;
                justify-content: space-between;

                .cantidad {
                    span {
                        margin: 0;
                        padding: 0;
                        font-size: 20px;
                        font-weight: v.$regular;
                    }
                }

                .botonMenos,
                .botonMas {
                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: cover;

                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .nombrePrecio {
            height: 58px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 22px;

            p {
                font-size: 24px;
                font-weight: v.$bold;
                margin: 0;
                color: lighten(v.$negro, 50);
            }
        }

        .imagenes {
            margin-bottom: 22px;

            .imagenSeleccionada {
                // height: 238px;
                height: 440px;
                max-width: 440px;
                width: 100%;
                border-radius: 20px;
                margin-bottom: 12px;
                background-color: #E7E8D8;

                img {
                    margin: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 20px;
                }
            }

            .miniaturas {
                display: flex;
                align-items: center;
                gap: 12px;

                img {
                    margin: 0;
                    border-radius: 20px;
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    background-color: #E7E8D8;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .atras {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 22px;

        a {
            max-width: 480px;
            width: 100%;

            img {
                margin: 0;
                width: 30px;
                height: 30px;
                object-fit: cover;
            }
        }
    }
}