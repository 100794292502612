// Typography
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');
$mainFont: "Cabin", sans-serif;

// Colors
$negro: #000000;
$blanco: #FFFFFF;
$gris: #eeeeee;
$verdeClaro: #8dd0a8;
$verde: #41B06E;
$rojoClaro: #f9c2c2;
$rojo: #F28585;
$azulClaro: #7BA7D0;
$amarilloClaro: #F2E7C9;
$amarillo: #F2C94C;

// Font weights
$thin: 300;
$regular: 400;
$bold: 700;
$black: 900;

// Animation
$alertAnimation: alertAnimation 1s forwards, alertExitAnimation 1s 9s forwards;