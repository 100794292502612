@use '../base/variables' as v;

.error404 {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .contenido {
        max-width: 340px;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        a {
            width: 220px;
            margin: 0 auto;
        }

        img {
            width: 120px;
            height: 120px;
            margin: 0 auto;
            object-fit: cover;
        }

        .texto {
            display: flex;
            flex-direction: column;
            gap: 16px;

            p {
                max-width: 287px;
                width: 100%;
                text-align: center;
                margin: 0 auto;
            }
        }
    }
}