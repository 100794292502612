@use '../base/variables' as v;

.formularioEliminar {
    margin-top: 15px;
}

.adminArticulos {

    .barraAcciones {
        margin-bottom: 34px;
    }

    h2 {
        margin-bottom: 24px;
    }

    .articulos {
        .contenidoArticulos {
            margin-bottom: 0;
        }
    }

    .articulosAdmin {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 15px;
        column-gap: 20px;

        .articuloAdmin {
            background-color: v.$gris;
            border-radius: 20px;
            padding: 10px;
            width: 210px;
            height: 216px;

            .contenido {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin: 0;
                height: auto;
                width: auto;
                color: v.$negro;
                height: 189px;

                img {
                    width: 190px;
                    height: 120px;
                    object-fit: cover;
                    margin: 0;
                    border-radius: 15px;
                }

                .fecha {
                    font-size: 12px;
                    font-weight: v.$bold;
                    color: lighten(v.$negro, 50);
                    max-width: 190px;
                    width: 100%;
                }

                .titulo {
                    height: 38px;
                    font-size: 16px;
                    max-width: 190px;
                    width: 100%;
                }
            }
        }
    }
}

// estilos para el formulario de crear articulo
.crearArticulo {
    h2 {
        margin-bottom: 35px;
    }

    .formularioEliminar {
        margin-top: 15px;
    }

    .formularioCrearArticulo {
        display: flex;
        flex-direction: column;
        gap: 35px;

        .nameDate {
            display: flex;
            flex-direction: column;
            gap: 1px;

            p {
                color: lighten(v.$negro, 50);
                font-size: 12px;
                font-weight: v.$bold;
            }
        }

        .titulo {
            border: none;
            height: 29px;
            padding: 0;
            font-size: 24px;
            font-weight: v.$bold;

            &:focus {
                outline: none;
            }
        }

        .imagen {
            height: 238px;
            border-radius: 20px;
            background-color: v.$gris;
            max-width: 440px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            img {
                position: absolute;
                width: 60px;
                height: 60px;
                object-fit: cover;
                aspect-ratio: 1/1;
            }

            &:hover {
                cursor: pointer;
            }

            input {
                opacity: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}