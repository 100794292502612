@use '../base/variables' as v;

.pagoExito {
    display: flex;
    flex-direction: column;
    align-items: center;
    // max-width: 337px;
    // width: 100%;

    &.error {

        .text {
            h2 {
                width: 340px;
            }

            p {
                width: 287px;
            }
        }
    }

    a {
        max-width: 220px;
    }

    img {
        width: 120px;
        height: 120px;
        aspect-ratio: 1/1;
        object-fit: cover;
        margin-bottom: 30px;
    }

    .text {
        width: 317px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        align-items: center;
        margin-bottom: 30px;

        h2 {
            font-size: 24px;
            font-weight: v.$bold;
            text-align: center;
        }

        p {
            width: 280px;
            font-size: 16px;
            text-align: center;
        }
    }
}